<template>
	<div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
        </div>

        <div class="layout-main-container">
            <div class="layout-main">
                <!-- <Dropdown v-model="dropdownValue" :options="partners" optionLabel="name" placeholder="Select Partner" /> -->
                <br>
                <br>
                <router-view />
            </div>
            <AppFooter />
        </div>

		<AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" />
        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';
// import PartnerService from './service/PartnerService';
// import { store } from './store/store.js'
import AuthEventBus from "./common/AuthEventBus";

export default {
    emits: ['change-theme'],
    data() {
        return {
            // dropdownValue: null,
            // partnerService: null,
            // partners: [],
            layoutMode: 'overlay',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            loaded: false,
            menu : [
                {
                    label: 'Dashboards',
                    items: [
                        {label: 'Home', icon: 'pi pi-fw pi-home', to: '/'},
                        {label: 'Main', icon: 'pi pi-fw pi-database',  command: () => {  
                            if(localStorage.getItem("partner_type") == 1){
                                this.$router.push({ name: 'dashboard' })
                            }else{
                                this.$router.push({ name: 'spenddashboard' })
                            }
                        }},
                        {label: 'Microtasks', icon: 'pi pi-check-square', to: '/microtasks'},
                        {label: 'Self Initiated', icon: 'pi pi-user', to: '/selfinitiated'},
                        {label: 'Questionnaire', icon: 'pi pi-pencil', to: '/questionnaire'},
                        {label: 'Store', icon: 'pi pi-shopping-cart', to: '/store'}
                    ]
                },
                {
                    label: 'Action',
                    items: [
                        {label: 'Logout', icon: 'pi pi-fw pi-sign-out', to: '/login', command: () => {  localStorage.removeItem("user"); localStorage.removeItem("partner"); localStorage.removeItem('partner_type') }},
                    ]
                }
            ]
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
        // dropdownValue: function () {
        //     localStorage.setItem('partner', this.dropdownValue.id)
        //     store.partner = this.dropdownValue.id
        // }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        },
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppConfig': AppConfig,
        'AppFooter': AppFooter,
    },
    mounted(){
		// this.partnerService.getUserPartners().then(data => {
        //     let partners = data.data
        //     Object.keys(partners).forEach(key => {
        //     this.partnerService.getPartner(partners[key].partner_id).then(x => this.partners.push(x.data));
        // })
        // });
        AuthEventBus.on("logout", () => {
            this.logOut();
        });
    },
    beforeUnmount() {
        AuthEventBus.remove("logout");
    },
    created(){
		// this.partnerService = new PartnerService();
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
