import axios from "axios";

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:8000/'
    : 'https://dashboard-api.zlto.co/'

    // headers: {
    //     "Content-Type": "application/json",
    // },
});



export default instance;