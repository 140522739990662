import api from "./api";
import TokenService from "./token.service";

// const API_URL = 'http://localhost:8000/login/';

class AuthService {
    login(user) {
        let form_data = new FormData();
        console.log(form_data)

        form_data.append('username', user.username)
        form_data.append('password', user.password)

        let config = {
            header : {
                'Content-Type' : 'multipart/form-data'
            }
        }

        return api.post('login', form_data, config)
            .then(response => {
            if (response.data.access_token) {
                TokenService.setUser(response.data);
                // localStorage.setItem('user', JSON.stringify(response.data));
                // localStorage.setItem('partner', response.data.default_partner)
            }

        return response.data;
        });
    }

    logout() {
        TokenService.removeUser();
    }

}

export default new AuthService();