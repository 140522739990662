import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'partners',
                component: () => import('./components/Partners.vue')
            },
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: '/spenddashboard',
                name: 'spenddashboard',
                component: () => import('./components/SpendDashboard.vue')
            },
            {
                path: '/microtasks',
                name: 'microtasks',
                component: () => import('./components/MicroTasks.vue')
            },
            {
                path: '/selfinitiated',
                name: 'selfinitiated',
                component: () => import('./components/SelfInitiated.vue')
            },
            {
                path: '/store',
                name: 'store',
                component: () => import('./components/Store.vue')
            },
            {
                path: '/questionnaire',
                name: 'questionnaire',
                component: () => import('./components/Questionnaire.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router;
